body {
  margin: 0;
  background-color: #F2F2F2;
  font-family: Montserrat, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-width: 1350px;
  overflow: hidden;
}


/* MASONRY */
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: 100px;
}
.my-masonry-grid_column {
  padding-left: 30px; 
  background-clip: padding-box;
}

.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  background: grey;
  margin-bottom: 30px;
}

html{
    -webkit-box-sizing: inherit;
    box-sizing: inherit; 
}

.go3009151065 {
  -webkit-box-sizing:border-box;
          box-sizing:border-box; 
}